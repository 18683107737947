.react-select-container {

  .react-select__control {
    @apply shadow-none #{!important} {}
    @apply rounded-xl focus:outline-none w-full #{!important} {}
    @apply bg-gray-900 border-2 border-transparent hover:border-primary focus:border-primary #{!important} {}
    @apply transition duration-150 {}
    &:hover {
      @apply shadow-none #{!important} {} } }

  .react-select__value-container {
    @apply p-4 #{!important} {}
    div:nth-child(2) {
      @apply absolute {} } }

  .react-select__single-value {
    @apply text-white m-0 relative top-0 transform-none max-w-full #{!important} {} }

  .react-select__placeholder {
    @apply m-0 relative top-0 transform-none text-gray-600 #{!important} {} }

  .react-select__indicators {}

  .react-select__dropdown-indicator {
    @apply p-4 #{!important} {} }

  .react-select__indicator-separator {
    @apply hidden {} }

  .react-select__menu {
    @apply m-0 border-2 border-gray-800 bg-gray-900 rounded-xl overflow-hidden #{!important} {} }

  .react-select__menu-list {
    @apply p-0 #{!important} {} }

  .react-select__option, .react-select__option--is-focused {
    @apply truncate bg-transparent {}
    &:focus, &:hover {
      @apply bg-gray-700 {} } }

  .react-select__option--is-selected {
    @apply bg-primary #{!important} {} } }

.react-select-container-sm {

  .react-select__control {
    @apply shadow-none #{!important} {}
    @apply rounded-xl focus:outline-none w-full #{!important} {}
    @apply bg-gray-900 border-2 border-transparent hover:border-primary focus:border-primary #{!important} {}
    @apply transition duration-150 {}
    &:hover {
      @apply shadow-none #{!important} {} } }

  .react-select__value-container {
    @apply px-4 py-2 #{!important} {}
    div:nth-child(2) {
      @apply absolute {} } }

  .react-select__single-value {
    @apply text-white m-0 relative top-0 transform-none max-w-full #{!important} {} }

  .react-select__placeholder {
    @apply m-0 relative top-0 transform-none text-gray-600 #{!important} {} }

  .react-select__indicators {}

  .react-select__dropdown-indicator {
    @apply px-4 py-2 #{!important} {} }

  .react-select__indicator-separator {
    @apply hidden {} }

  .react-select__menu {
    @apply m-0 border-2 border-gray-800 bg-gray-900 rounded-xl overflow-hidden #{!important} {} }

  .react-select__menu-list {
    @apply p-0 #{!important} {} }

  .react-select__option, .react-select__option--is-focused {
    @apply truncate bg-transparent {}
    &:focus, &:hover {
      @apply bg-gray-700 {} } }

  .react-select__option--is-selected {
    @apply bg-primary #{!important} {} } }
