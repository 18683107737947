.btn-gray {
  @apply bg-gray-900 hover:bg-gray-800 rounded-lg px-5 py-2 {}
  @apply transition duration-150 focus:outline-none {} }

.btn-primary {
  @apply bg-primary hover:bg-gray-900 rounded-lg px-5 py-2 {}
  @apply transition duration-150 focus:outline-none {} }

.btn-gray-border {
  @apply border-2 border-gray-900 hover:border-primary rounded-lg px-5 py-2 {}
  @apply transition duration-150 focus:outline-none {} }

.btn-gray-800-border {
  @apply border-2 border-gray-800 hover:border-primary rounded-lg px-5 py-2 {}
  @apply transition duration-150 focus:outline-none {} }

.btn-primary-border {
  @apply border-2 border-primary hover:border-gray-900 rounded-lg px-5 py-2 {}
  @apply transition duration-150 focus:outline-none {} }

.btn-circle-gray {
  @apply bg-gray-900 hover:bg-gray-800 rounded-full h-10 w-10 focus:outline-none flex justify-center items-center {}
  @apply transition duration-150 {} }

.btn-circle-primary {
  @apply bg-primary hover:bg-primary-400 rounded-full h-10 w-10 focus:outline-none flex justify-center items-center {}
  @apply transition duration-150 {} }

.btn-circle-primary-lg {
  @apply bg-primary hover:bg-primary-400 rounded-full h-14 w-14 focus:outline-none flex justify-center items-center {}
  @apply transition duration-150 {} }
