@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

@tailwind base {}
@tailwind components {}
@tailwind utilities {}

@layer base {
  html, body {
    @apply bg-black text-white font-body {}
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased; } }

.fade-in {
  animation: fadeIn 440ms ease; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-up {
  opacity: 0;
  animation: fadeUp 550ms ease;
  animation-delay: 440ms;
  animation-fill-mode: forwards; }

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translate3d(0,40px,0); }
  100% {
    opacity: 1;
    transform: translate3d(0,0,0); } }

@import 'styles/button.sass';
@import 'styles/input.sass';
@import 'styles/tooltip.sass';
@import 'styles/react-select.sass';
